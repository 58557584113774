var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',[_c('validation-observer',{ref:"wifiSettingsModal"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"MonitorIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Add WiFi Network")])],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Network Name:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Network Name:","vid":"lan_id","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"name","placeholder":"Select network","options":_vm.networks,"is-open":"","open":"","return-object":"","filled":"","searchable":true},on:{"search":_vm.onSearchNetwork},model:{value:(_vm.network),callback:function ($$v) {_vm.network=$$v},expression:"network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"SSID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"SSID:","vid":"ssid","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"SSID:","autocomplete":"off"},model:{value:(_vm.form.ssid),callback:function ($$v) {_vm.$set(_vm.form, "ssid", $$v)},expression:"form.ssid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Authentication Type:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Authentication Type","vid":"authentication_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.authList2G,"reduce":function (authentication_type) { return authentication_type.id; },"searchable":false,"label":"name"},model:{value:(_vm.form.authentication_type),callback:function ($$v) {_vm.$set(_vm.form, "authentication_type", $$v)},expression:"form.authentication_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.form.authentication_type !== 'none' || !_vm.form.authentication_type)?_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Password:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Password:","vid":"password","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Password:","autocomplete":"off","type":_vm.passwordFieldType},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon()},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1050106683)})],1)],1):_vm._e(),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("SSID Visible:")]),_c('validation-provider',{attrs:{"name":"SSID Visible:","vid":"is_visible"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"is_visible","switch":"","inline":""},model:{value:(_vm.form.is_visible),callback:function ($$v) {_vm.$set(_vm.form, "is_visible", $$v)},expression:"form.is_visible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("Is 5G same as 2G:")]),_c('validation-provider',{attrs:{"name":"Is 5G same as 2G","vid":"is5GSame"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"is_same","switch":"","inline":""},model:{value:(_vm.is5GSame),callback:function ($$v) {_vm.is5GSame=$$v},expression:"is5GSame"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.is5GSame)?_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"MonitorIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("5G WiFi Network")])],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"SSID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"SSID:","vid":"ssid","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"SSID:","autocomplete":"off"},model:{value:(_vm.form.g5.ssid),callback:function ($$v) {_vm.$set(_vm.form.g5, "ssid", $$v)},expression:"form.g5.ssid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,635884978)})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Authentication Type:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Authentication Type","vid":"authentication_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.authList5G,"reduce":function (authentication_type) { return authentication_type.id; },"searchable":false,"label":"name"},model:{value:(_vm.form.g5.authentication_type),callback:function ($$v) {_vm.$set(_vm.form.g5, "authentication_type", $$v)},expression:"form.g5.authentication_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,24058001)})],1)],1),(_vm.form.g5.authentication_type === 'wpa2-psk,wpa3-psk' || _vm.form.g5.authentication_type === 'wpa3-psk')?_c('h5',{staticStyle:{"color":"#F4B32A","margin-bottom":"20px"}},[_vm._v(" Warning: Using WPA3 in a mixed 802.11ac and 802.11ax environment may cause issues with roaming between radio types. To ensure seamless roaming across different radios, we recommend using WPA2 throughout your network. ")]):_vm._e(),(_vm.form.g5.authentication_type !== 'none')?_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Password:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Password:","vid":"password","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Password:","autocomplete":"off","type":_vm.passwordFieldType5G},model:{value:(_vm.form.g5.password),callback:function ($$v) {_vm.$set(_vm.form.g5, "password", $$v)},expression:"form.g5.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon5G()},on:{"click":_vm.togglePasswordVisibility5G}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2508195093)})],1)],1):_vm._e(),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("SSID Visible:")]),_c('validation-provider',{attrs:{"name":"SSID Visible:","vid":"is_visible"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"is_visible","switch":"","inline":""},model:{value:(_vm.form.g5.is_visible),callback:function ($$v) {_vm.$set(_vm.form.g5, "is_visible", $$v)},expression:"form.g5.is_visible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1447498168)})],1)],1)]):_vm._e(),_c('p',{staticClass:"mb-0 mt-3"},[_vm._v(" Encryption: ")]),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("AES CCM:")]),_c('validation-provider',{attrs:{"name":"AES CCM:","vid":"aes_ccm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"aes_ccm","switch":"","inline":""},model:{value:(_vm.form.aes_ccm),callback:function ($$v) {_vm.$set(_vm.form, "aes_ccm", $$v)},expression:"form.aes_ccm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("TKIP:")]),_c('validation-provider',{attrs:{"name":"TKIP:","vid":"tkip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"tkip","switch":"","inline":""},model:{value:(_vm.form.tkip),callback:function ($$v) {_vm.$set(_vm.form, "tkip", $$v)},expression:"form.tkip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("Limit bridge horizon:")]),_c('validation-provider',{attrs:{"name":"SSID Visible:","vid":"is_limit_bridge"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"is_limit_bridge","switch":"","inline":""},model:{value:(_vm.form.is_limit_bridge),callback:function ($$v) {_vm.$set(_vm.form, "is_limit_bridge", $$v)},expression:"form.is_limit_bridge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("Client-to-client forwarding:")]),_c('validation-provider',{attrs:{"name":"SSID Visible:","vid":"client_forwarding"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"client_forwarding","switch":"","inline":""},model:{value:(_vm.form.client_forwarding),callback:function ($$v) {_vm.$set(_vm.form, "client_forwarding", $$v)},expression:"form.client_forwarding"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container mt-2 switch-row-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Country","label-for":"country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"reduce":function (country) { return country.name; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Installation","label-for":"installation","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Installation","vid":"installation","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.installations,"reduce":function (installation) { return installation.id; },"placeholder":"Select installation","label":"name"},model:{value:(_vm.form.installation),callback:function ($$v) {_vm.$set(_vm.form, "installation", $$v)},expression:"form.installation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary","disabled":_vm.disabledSaveButton},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button-request',{attrs:{"disabled":_vm.disabledSaveButton,"text":"Create","request":_vm.addItem}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }